const getCodeStatusSolicitacaoPagto = {
  AGUARDANDO_AUTORIZACAO_COMISSAO: "AGUARDANDO_AUTORIZACAO_COMISSAO",
  EMPATE: "EMPATE",
  AUTORIZADO: "AUTORIZADO",
  RECUSADO_PELA_COMISSAO: "RECUSADO_PELA_COMISSAO",
  RECUSADO_PELA_PLATAFORMA: "RECUSADO_PELA_PLATAFORMA",
  RECUSADO_SEM_SALDO: "RECUSADO_SEM_SALDO",
  RECUSADO: "RECUSADO",
  PAGO: "PAGO",
  CANCELADO: "CANCELADO"
};

function getListStatusSolicitacaoPagto() {
  try {
    let arrayList = [];
    for (let itemRoot in getCodeStatusSolicitacaoPagto) {
      let item = {
        text: getStatusNameSolicitacaoPagto(itemRoot),
        value: itemRoot
      };
      arrayList.push(item);
    }
    return arrayList;
  } catch (e) {
    console.error("fail getListStatusSolicitacaoPagto array", e);
    return [];
  }
}

function getStatusNameSolicitacaoPagto(code) {
  switch (code) {
    case getCodeStatusSolicitacaoPagto.AGUARDANDO_AUTORIZACAO_COMISSAO:
      return "Aguardando autorização da comissão";
    case getCodeStatusSolicitacaoPagto.RECUSADO_PELA_COMISSAO:
      return "Recusado por falta de autorização da comissão";
    case getCodeStatusSolicitacaoPagto.RECUSADO_PELA_PLATAFORMA:
      return "Pagamento recusado";
    case getCodeStatusSolicitacaoPagto.RECUSADO_SEM_SALDO:
      return "Recusado sem saldo";
    case getCodeStatusSolicitacaoPagto.RECUSADO:
      return "Recusado";
    case getCodeStatusSolicitacaoPagto.EMPATE:
      return "Empate";
    case getCodeStatusSolicitacaoPagto.AUTORIZADO:
      return "Aguardando pagamento";
    case getCodeStatusSolicitacaoPagto.PAGO:
      return "Pago";
    case getCodeStatusSolicitacaoPagto.CANCELADO:
      return "Cancelado";
  }
}

export {
  getStatusNameSolicitacaoPagto,
  getCodeStatusSolicitacaoPagto,
  getListStatusSolicitacaoPagto
};
